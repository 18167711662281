import { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import queryString from 'query-string';
import { ComponentProps, FC, useMemo } from 'react';

import { MySlotFragment } from '@/generated/graphql';

export type RouterPathType =
  | `/`
  | `/signin`
  | `/signup`
  | `/signup/phone-number`
  | `/slots`
  | `/slots/recommended`
  | `/slots/upcoming`
  | `/history`
  | `/profile`
  | `/profile/edit`
  | `/profile/add`
  | `/preference`
  | `/settings`
  | `/settings/change-password`
  | `/settings/email-addresses`
  | `/settings/change-phonenumber`
  | `/notifications`
  | `/account/password/reset`
  | `/account/password/reset/done`
  | `/door/calendar`
  | `/404`
  | `/500`;

export type RouterPathBuilder =
  | {
      type: 'slot-detail';
      slotUuid: string;
    }
  | {
      type: 'recommendation-detail';
      recommendationUuid: string;
    }
  | {
      type: 'nt-rec-detail';
      ntRecUuid: string;
    }
  | {
      type: 'artist-details';
      artistUuid: string;
    }
  | {
      type: 'signup-draft';
      signupDraftUuid: string;
    }
  | {
      type: 'door-calendar';
      date: string;
      monthly: boolean;
    }
  | {
      type: 'signup-draft-submission';
      signupDraftUuid: string;
    };

export class RouterPathBuilderUtil {
  public static fromSlot(slot: MySlotFragment): RouterPathBuilder {
    if (slot.recommendation) {
      return {
        type: `recommendation-detail`,
        recommendationUuid: slot.recommendation.uuid,
      };
    }

    if (slot.ntRec) {
      return {
        type: `nt-rec-detail`,
        ntRecUuid: slot.ntRec.uuid,
      };
    }

    return {
      type: `slot-detail`,
      slotUuid: slot.uuid,
    };
  }
}

const buildRouterPath = (href: RouterPathType | RouterPathBuilder): string => {
  if (typeof href === `string`) {
    return href;
  }

  switch (href.type) {
    case `slot-detail`: {
      const { slotUuid } = href;

      return `/slots/${slotUuid}`;
    }
    case `recommendation-detail`: {
      const { recommendationUuid } = href;

      return `/r/${recommendationUuid}`;
    }
    case `nt-rec-detail`: {
      const { ntRecUuid } = href;

      return `/ntrecs/${ntRecUuid}`;
    }
    case `artist-details`: {
      const { artistUuid } = href;

      return `/artists/${artistUuid}`;
    }
    case `signup-draft`: {
      const { signupDraftUuid } = href;

      return `/profile/add/${signupDraftUuid}`;
    }
    case `door-calendar`: {
      const { date, monthly } = href;

      return `/door/calendar?date=${date}&monthly=${monthly}`;
    }
    case `signup-draft-submission`: {
      const { signupDraftUuid } = href;

      return `/profile/add/${signupDraftUuid}/view`;
    }
    default: {
      throw new Error(`Invalid href type: ${href}`);
    }
  }
};

function useInternalRouter() {
  const router = useRouter();
  const searchParams = useSearchParams();

  return useMemo(() => {
    const a = searchParams.get(`a`);

    return {
      ...router,
      push(
        path: RouterPathType | RouterPathBuilder,
        options?: NavigateOptions,
      ) {
        const href = a
          ? `${buildRouterPath(path)}?a=${a}`
          : buildRouterPath(path);

        return router.push(href, options);
      },
      replace(
        path: RouterPathType | RouterPathBuilder,
        options?: NavigateOptions,
      ) {
        const href = a
          ? `${buildRouterPath(path)}?a=${a}`
          : buildRouterPath(path);

        return router.replace(href, options);
      },
    };
  }, [router, searchParams]);
}

export type InternalLinkProps = Omit<ComponentProps<typeof Link>, 'href'> &
  (
    | {
        href: RouterPathType;
        builder?: undefined;
      }
    | {
        href?: undefined;
        builder: RouterPathBuilder;
      }
    | {
        href: null;
        builder?: undefined;
      }
  );

function InternalLink(props: InternalLinkProps): ReturnType<FC> {
  const { href, children, builder, ...others } = props;
  const searchParams = useSearchParams();

  const a = searchParams.get(`a`);

  if (href === null) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  const path = buildRouterPath(href || builder);

  const parsed = queryString.parseUrl(path);

  return (
    <Link
      href={queryString.stringifyUrl({
        url: parsed.url,
        query: {
          ...parsed.query,
          a: a || undefined,
        },
      })}
      {...others}
    >
      {children}
    </Link>
  );
}

export { useInternalRouter, InternalLink };
