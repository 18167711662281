import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getLastPartOfURL(url: string) {
  const parts = url.split(`/`);
  const lastPart = parts.pop() || parts.pop();

  return lastPart || ``;
}

export function removeQueryString(url: string) {
  return url.split(`?`)[0];
}
