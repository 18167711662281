import { UilSadDizzy } from '@iconscout/react-unicons';
import { captureException } from '@sentry/core';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import ErrorPage from '@/components/Pages/Error';

function InternalServerError(props: { error?: Error }) {
  const { error } = props;

  useEffect(() => {
    if (error && error.message) {
      toast.error(`${error}`);
      captureException(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorPage
      title="Something went wrong.."
      message="Please try again in a few minutes"
      icon={<UilSadDizzy className="size-48" />}
    />
  );
}

export default InternalServerError;
