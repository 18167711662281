import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  `inline-flex items-center justify-center whitespace-nowrap transition-colors focus-visible:outline-none disabled:pointer-events-none`,
  {
    variants: {
      variant: {
        default: `text-gray-25 hover:bg-accent`,
        primary: `!bg-orange-200 !text-white hover:!bg-orange-300 disabled:!bg-gray-500 disabled:!text-gray-200`,
        primary2: `!bg-gray-10 !text-white hover:!bg-gray-50 disabled:!bg-gray-500 disabled:!text-gray-200`,
        [`primary-destructive`]: `!bg-danger-red-100 text-white hover:!bg-danger-red-200 disabled:!bg-gray-500 disabled:!text-gray-200`,
        destructive: `bg-destructive text-destructive-foreground hover:bg-destructive/90`,
        outline: `border border-input bg-background hover:bg-accent hover:text-accent-foreground focus-visible:border-orange-300 `,
        [`outline-dark`]: `border border-gray-50 hover:bg-gray-10 focus-visible:border-orange-300 disabled:opacity-40`,
        secondary: `bg-white text-gray-25 border border-gray-300 hover:border-orange-300 hover:bg-orange-50 disabled:bg-gray-500 disabled:text-gray-200 disabled:border-gray-300`,
        [`secondary-destructive`]: `bg-white text-danger-red-100 border border-gray-300 hover:border-danger-red-100 disabled:bg-gray-500 disabled:text-gray-200 disabled:border-gray-300`,
        ghost: `hover:bg-accent hover:text-accent-foreground`,
        link: `text-primary underline-offset-4 hover:underline`,
        white: `bg-white text-gray-25 hover:bg-gray-500`,
      },
      size: {
        default: `h-50 px-16 py-6 rounded-8 paragraph-medium`,
        sm: `py-4 px-10 h-32 paragraph-medium rounded-8`,
        lg: `h-56 p-16 header-4-m rounded-12 label-medium`,
        icon: `size-32 rounded-8`,
      },
    },
    defaultVariants: {
      variant: `default`,
      size: `default`,
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : `button`;

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Button.displayName = `Button`;

export { Button, buttonVariants };
