import { UilSadDizzy } from '@iconscout/react-unicons';
import { ReactNode } from 'react';

import { Button } from '@/components/ui/button';
import { InternalLink } from '@/hooks/useInternalRouter';

function ErrorPage(props: {
  children?: ReactNode;
  icon?: ReactNode;
  title: string;
  message: ReactNode;
}) {
  const { icon, title, message } = props;

  return (
    <div className="flex-center w-full max-md:px-16 max-md:pt-140 md:pt-264">
      <div className="flex w-343 flex-col items-center">
        <div className="flex-center size-80 rounded-full bg-gray-500">
          <div className="size-48 text-gray-200">
            {icon ?? <UilSadDizzy className="size-48" />}
          </div>
        </div>
        <div className="h-32" />
        <div className="heading-xlarge text-center text-gray-10">{title}</div>
        <div className="h-16" />
        <span className="paragraph-medium text-center text-gray-75">
          {message}
        </span>
        <div className="h-72" />
        <InternalLink href="/" className="contents">
          <Button size="lg" variant="primary" className="!h-62 w-full">
            Home
          </Button>
        </InternalLink>
      </div>
    </div>
  );
}

export default ErrorPage;
